import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import '../styles/post.scss'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import { useCookies } from 'react-cookie'
import InnerHTML from 'dangerously-set-html-content'
import { decode } from 'html-entities'

// Components
import Layout from '../components/Layout/Layout'

const Post = ({ data, context }) => {
  const post = data.wordpressPost

  const [gated, setGated] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()
  useEffect(() => {
    console.log(post.categories)
    if (post.categories.find(x => x.slug === 'gated')) {
      if (!cookies['we_said_hello']) {
        setGated(true)

        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/v2.js'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: '5666503',
              formId: '00fdd259-4804-4b64-ae80-c6d8e09c1a97',
              target: '#hubspot_form',
            })
          }
          window.addEventListener('message', event => {
            if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
              console.log('Form Submitted!')
              setCookie('we_said_hello')
              setGated(false)
            }
          })
        })
      }
    }
  }, [])

  if (!gated) {
    return (
      <Layout title={post.title.replace(/(<([^>]+)>)/gi, '')}>
        <React.Fragment>
          <Helmet>
            <meta
              name="description"
              content={decode(
                post.excerpt.replace(/(<([^>]+)>)/gi, '').length > 155
                  ? post.excerpt.replace(/(<([^>]+)>)/gi, '').slice(0, 155) + '...'
                  : post.excerpt.replace(/(<([^>]+)>)/gi, '')
              )}
            />
            <meta
              name="og:description"
              content={decode(
                post.excerpt.replace(/(<([^>]+)>)/gi, '').length > 155
                  ? post.excerpt.replace(/(<([^>]+)>)/gi, '').slice(0, 155) + '...'
                  : post.excerpt.replace(/(<([^>]+)>)/gi, '')
              )}
            />
            <meta
              name="twitter:description"
              content={decode(
                post.excerpt.replace(/(<([^>]+)>)/gi, '').length > 155
                  ? post.excerpt.replace(/(<([^>]+)>)/gi, '').slice(0, 155) + '...'
                  : post.excerpt.replace(/(<([^>]+)>)/gi, '')
              )}
            />
            <meta name="twitter:card" content="summary_large_image" />
            {post.featured_media && (
              <meta
                name="og:image"
                content={
                  'https://collegepulse.com' +
                  post.featured_media.localFile.childImageSharp.fixed.src
                }
              />
            )}

            {post.featured_media && (
              <meta
                name="image"
                property="og:image"
                content={
                  'https://collegepulse.com' +
                  post.featured_media.localFile.childImageSharp.fixed.src
                }
              />
            )}

            {post.featured_media && (
              <meta
                name="twitter:image"
                content={
                  'https://collegepulse.com' +
                  post.featured_media.localFile.childImageSharp.fixed.src
                }
              />
            )}

            <title>{decode(post.title.replace(/(<([^>]+)>)/gi, ''))}</title>
            <meta name="twitter:title" content={decode(post.title.replace(/(<([^>]+)>)/gi, ''))} />
            <meta name="og:title" content={decode(post.title.replace(/(<([^>]+)>)/gi, ''))} />

            <meta name="author" content="College Pulse" />
            <meta property="article:published_time" content={post.date} />
          </Helmet>
          <article className="blogpost-container container content has-padding-bottom-6">
            <header style={{ marginBottom: post.featured_media ? '-2rem' : '0' }}>
              {post.acf.background_image && post.acf.background_image.localFile ? (
                <Img
                  className="is-fullwidth"
                  fluid={post.acf.background_image.localFile.childImageSharp.fluid}
                />
              ) : (
                post.featured_media && (
                  <Img
                    className="is-fullwidth"
                    fluid={post.featured_media.localFile.childImageSharp.fluid}
                  />
                )
              )}

              <div className={`${post.featured_media ? 'title-box' : 'title-box-no-image'}`}>
                <h1
                  dangerouslySetInnerHTML={{ __html: post.title }}
                  className="title has-text-weight-bold is-marginless"
                />
                <small
                  className="has-padding-top-2"
                  dangerouslySetInnerHTML={{ __html: post.date }}
                />
              </div>
            </header>

            <main>
              <InnerHTML html={post.content} />
            </main>
          </article>

          <section className="view-more-blog container blogpost-container has-margin-bottom-4">
            <Link to="/blog/page/1">
              <div className="content card has-padding-4 has-background-primary">
                <h1 className="title is-marginless has-text-white has-text-weight-bold">
                  View More Research!
                </h1>
                <p className="has-margin-top-2 has-text-white has-text-weight-light is-size-5">
                  Like what you see? Click here to view more of our public research on College
                  Students.
                </p>
              </div>
            </Link>
          </section>
        </React.Fragment>
      </Layout>
    )
  } else {
    return (
      <section className="is-centered has-background-info is-fullheight is-flex-column">
        <h1 className="title has-text-white has-text-weight-light is-4">
          To view the requested contact, please fill out the following form.
        </h1>
        <div
          className="box is-radiusless"
          style={{ position: 'relative', width: '90%', maxWidth: '900px' }}
        >
          <div id="hubspot_form" />
        </div>
      </section>
    )
  }
}

export default Post

export const postQuery = graphql`
  query ($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      id
      content
      slug
      link
      categories {
        slug
      }
      date(formatString: "MMMM Do, YYYY")
      excerpt
      acf {
        background_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(height: 630, width: 1200, toFormat: PNG, quality: 50) {
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
